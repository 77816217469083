import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import VierSwiper from "../components/vierSwiper"
import { device } from "../utils/devices"
import TruncateMarkup from "react-truncate-markup"
import { AllWordpressWpProjekte } from "../hooks/allWordpressWpProjekte"
import { SwiperSlide } from "swiper/react"

const StyledTitle = styled.div`
    font-weight: bold;
    text-transform: uppercase;
  //  white-space: nowrap;
    font-size: 30px;
    text-shadow: 1px -1px 0px #ffffff45;
    @media ${device.tablet} {  
      width: 100%;                   
      // overflow: hidden;  
      text-overflow: ellipsis;
      line-height: 1;
      position: absolute;
      top: 100%;
      z-index: 2;
    }
`

const StyledText = styled(TruncateMarkup)`
    display: none;
    @media ${device.tablet} {  
      display: block;
    }
`

const StyledContent = styled.div``

const StyledLink = styled(Link)`
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
`

const StyledSwiperItems = styled.div`
  display: flex;
  
  div {
    width: 100%;
    padding: 8px;
  }
  
  @media ${device.tablet} {  
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;     
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(15, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    
    > div {
      padding: 0;
    }
    
    > div:nth-child(1) {
      grid-area: 2 / 2 / 12 / 6;          
      ${StyledTitle} {
 
        z-index: 1;
        bottom: -60px;
        right: -120px;
        width: 100%;
        font-size: 60px;
        top: auto;
      }
    }    
    
    > div:nth-child(2) {
      grid-area: 1 / 7 / 5 / 10;              
    }  
    
    > div:nth-child(3) {         
      grid-area: 2 / 10 / 7 / 12;        
    }
        
    > div:nth-child(4) {
      grid-area: 8 / 7 / 16 / 10;
        ${StyledTitle} {
          position: relative;
        }
        ${StyledContent} {
          width: 100%;
          left: 100%;
          top: 50%;
          padding-left: 16px;
          position: absolute;
          transform: translateY(-50%);
        }
    } 
  }
`


const StyledAspectRatio = styled.div`
    @media ${device.tablet} {  
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      overflow: hidden;
      &::before {
        padding-top: 75%;
        display: block;
        content: "";
      }
    }
`

const swiperparams = {
  slidesPerView: 0.33,
  autoHeight: true,
  freeMode: true,
  navigation: true,
  breakpoints: {
    768: {
      freeMode: false,
      slidesPerView: 1,
    },
  },
}

export const ProjectSwiper = () => {

  const data = AllWordpressWpProjekte()

  let slices = []
  for (let i = 0; i < data.allWpAcfProjekt.nodes.length; i += 4) {
    slices.push(data.allWpAcfProjekt.nodes.slice(i, i + 4))
  }


  return (
    <VierSwiper params={swiperparams}>
      {slices.map((item, index) => (

          <SwiperSlide key={index}>
            <div className={"container"} css={{
              boxSizing: "border-box",
            }}>
              <div className={"row"}>
                <div className={"col-12"}>
                  <StyledAspectRatio>
                    <StyledSwiperItems>
                      {item.map((subitem, subindex) => {
                          let subItemText
                          if (typeof window !== `undefined`) {
                            const subItemHTML = `<div>${subitem.content}</div>`
                            let container = document.createElement("div")
                            container.innerHTML = subItemHTML
                            subItemText = container.innerText
                          }

                          return (
                            <div key={subindex}>
                              <StyledLink key={subindex} to={subitem.link}>
                                <StyledContent>
                                  <StyledTitle dangerouslySetInnerHTML={{ __html: subitem.title }}/>
                                  {subindex === 3 &&
                                  <StyledText lines={5}>
                                    <div>
                                      {subItemText}
                                    </div>
                                  </StyledText>
                                  }
                                </StyledContent>
                                {subitem.featuredImage.node.localFile &&
                                <Img className={"box-shadow"} style={{ height: "100%" }}
                                     fluid={subitem.featuredImage.node.localFile.childImageSharp.large}/>
                                }
                              </StyledLink>
                            </div>
                          )
                        },
                      )
                      }
                    </StyledSwiperItems>
                  </StyledAspectRatio>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ),
      )}
    </VierSwiper>
  )
}

