import { useStaticQuery, graphql } from "gatsby"

export const AllWordpressWpProjekte = () => (
  useStaticQuery(
    graphql`
        query {
            allWpAcfProjekt(sort: {fields: menuOrder, order: ASC}) {
                nodes {
                    slug
                    link
                    contentType {
                        node {
                            name
                        }
                    }
                    content
                    title
                    AcfProjektDetails {
                        projectPhotographer {
                            ... on WpAcfFotograf {
                                title
                                slug
                            }
                        }
                    }
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    #Medium
                                    fluid(
                                        maxWidth: 360,
                                        maxHeight: 200,
                                        quality: 80,
                                        cropFocus: CENTER ) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                    large: fluid(
                                        maxWidth: 540,
                                        quality: 80,
                                        cropFocus: CENTER ) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
  )
)
